<template>
  <div>
    <el-select
      v-loading="loading"
      class="w-full mb-3"
      size="medium"
      v-model="search"
      filterable
      @change="selectProduct"
      placeholder="Выбрать товар"
    >
      <el-option
        v-for="p in products"
        :key="`${p.product_id} - ${p.pre_order}`"
        :value="p.product_id"
        :label="`${p.product_name} - ${p.total - p.pre_order - p.booked}`"
      ></el-option>
    </el-select>
  </div>
</template>

<script>
import { getStorageProducts } from "@/api/storage";

export default {
  name: "SearchCatalogProducts",
  props: {
    warehouseId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      search: null,
      loading: false,
      products: [],
    };
  },
  watch: {
    async warehouseId() {
      await this.getStorageProducts();
    },
  },
  async mounted() {
    await this.getStorageProducts();
  },
  methods: {
    async getStorageProducts() {
      this.loading = true;
      this.products = (
        await getStorageProducts({
          limit: 10000,
          warehouse_id: this.warehouseId,
        })
      ).storage;
      this.loading = false;
    },
    selectProduct(productId) {
      const product = this.products.find((p) => p.product_id === productId);
      const { product_id, product_name } = product;
      this.$emit("select:product", { id: product_id, name: product_name });
      this.search = null;
    },
  },
};
</script>

<style scoped></style>
