<template>
  <div>
    <div class="mb-3">
      <p class="text-gray-500 mb-1">Выберите счет</p>
      <div class="flex">
        <el-select v-model="account_id" class="w-full mr-2" size="medium">
          <el-option
            v-for="x in accounts"
            :key="x.id"
            :label="`${x.name} - ${x.balance}тг`"
            :value="x.id"
          ></el-option>
        </el-select>
        <el-input
          size="medium"
          type="number"
          v-model.number="sum"
          class="mr-2"
        ></el-input>
        <el-button
          :disabled="!account_id || !sum"
          @click="addPayment"
          size="medium"
          >Добавить</el-button
        >
      </div>

      <ul class="mt-3">
        <li v-for="(p, index) in payments" :key="index">
          {{ getAccountName(p.account_id) }}: {{ p.sum }}
          <i @click="() => deletePayment(p)" class="el-icon-delete"></i>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getAccounts } from "@/api/transactions";
export default {
  name: "payments",
  props: {
    payments: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      account_id: null,
      sum: 0,
      accounts: [],
    };
  },
  async mounted() {
    this.accounts = await getAccounts();
  },
  methods: {
    addPayment() {
      this.$emit("add:payment", { account_id: this.account_id, sum: this.sum });
      this.account_id = null;
      this.sum = 0;
    },
    deletePayment({ account_id, sum }) {
      this.$emit("delete:payment", { account_id: account_id, sum: sum });
    },
    getAccountName(accountId) {
      const account = this.accounts.find((a) => a.id === accountId);
      return (account && account.name) || accountId;
    },
  },
};
</script>

<style scoped></style>
