<script>
import { getOrderClient } from "@/api/accounting";

export default {
  name: "OrderReviews",
  props: ["orderCode", "products"],
  data() {
    return {
      client: null,
    };
  },
  mounted() {
    getOrderClient(this.orderCode).then((res) => {
      if (res.client) {
        this.client = res.client;
      }
    });
  },
  methods: {
    async copyLink(kaspiSku) {
      const text = `https://kaspi.kz/shop/review/productreview?orderCode=${this.orderCode}&productCode=${kaspiSku}&started_by=shop_order_details&rating=5`;
      await navigator.clipboard.writeText(text);
      this.$message.success(`Скопировано`);
    },
  },
};
</script>

<template>
  <div class="p-4">
    <h2 class="mt-0 mb-3 text-xl flex justify-between items-center font-medium">
      Отзыв
    </h2>
    <section>
      <div class="font-medium">1 Шаг. Скопируйте ссылку на страницу отзыва</div>
      <ul>
        <li
          v-for="p in products"
          :key="p.id"
          class="flex items-center justify-between"
        >
          {{ p.name }}
          <el-button
            @click="() => copyLink(p.kaspi_sku)"
            icon="el-icon-copy-document"
            size="small"
            round
            type="primary"
            plain
            title="Скопировать"
          ></el-button>
        </li>
      </ul>
    </section>

    <section v-if="client">
      <div class="font-medium">2 Шаг. Откройте ссылку whatsapp</div>
      <a
        class="text-blue-600 underline"
        :href="`https://wa.me/7${client.phone}?text=`"
        target="_blank"
        >Перейти в whatsapp</a
      >
    </section>

    <section v-if="client">
      <div class="font-medium">
        3 Шаг. Отправьте скопированный текст клиенту
      </div>
    </section>
  </div>
</template>

<style scoped>
section {
  & + section {
    @apply mt-5;
  }
}
</style>
