<template>
  <el-drawer :visible="show" @close="close">
    <el-form class="px-5" label-position="left">
      <el-form-item label="Выберите продукт">
        <SearchStorageProducts
          :warehouse-id="warehouseId"
          @select:product="fetchMissingProduct"
        />
      </el-form-item>

      <template v-if="product">
        <div>
          Выбранный товар: <br />
          {{ product.id }}. {{ product.name }}
        </div>
        <el-form-item label="Выберите модификатор">
          <el-select v-model="modificator" size="small" class="w-full">
            <el-option
              v-for="m in modificators"
              :key="m.id"
              :value="`${m.sku}:${m.kaspi_sku}`"
            >
              {{ m.sku }} - {{ m.kaspi_sku }}
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Количество">
          <el-input
            v-model.number="product.count"
            @input="handleChange"
            type="number"
            size="small"
          ></el-input>
        </el-form-item>
        <el-form-item label="Цена за единицу">
          <el-input
            v-model.number="product.basePrice"
            @input="handleChange"
            size="small"
            type="number"
          ></el-input>
        </el-form-item>
        <el-form-item label="Сумма">
          <el-input
            :value="product.totalPrice"
            size="small"
            disabled
          ></el-input>
        </el-form-item>

        <pre>{{ product }}</pre>

        <div>
          <el-button @click="fill" size="small" round plain type="primary"
            >Добавить товар к уходу</el-button
          >
        </div>
      </template>
    </el-form>
  </el-drawer>
</template>

<script>
import SearchStorageProducts from "@/components/SearchStorageProducts.vue";
import { getProductModifications } from "@/api/product";
export default {
  name: "FillMissingProduct",
  components: { SearchStorageProducts },
  props: ["warehouseId", "diffSum"],
  data() {
    return {
      show: true,
      product: null,
      modificators: [],
    };
  },
  computed: {
    totalPrice() {
      return this.product.count * this.product.basePrice;
    },
    modificator: {
      get() {
        if (!this.product.sku) return null;
        return `${this.product.sku}:${this.product.kaspi_sku}`;
      },
      set(val) {
        const [sku, kaspi_sku] = val.split(":");
        this.product.sku = sku;
        this.product.kaspi_sku = kaspi_sku;
      },
    },
  },
  methods: {
    async fetchMissingProduct({ id, name }) {
      this.product = {
        id,
        name,
        sku: null,
        kaspi_sku: null,
        count: 1,
        basePrice: this.diffSum,
        totalPrice: this.diffSum,
      };
      this.modificators = await getProductModifications(id);
    },
    handleChange() {
      this.product.totalPrice = this.product.count * this.product.basePrice;
    },
    close() {
      this.show = false;
    },
    fill() {
      this.$emit("fill", { ...this.product });
      this.close();
    },
  },
};
</script>

<style scoped></style>
